<template>
  <b-row>
    <b-col cols="12">
      <mapa-picking
        v-if="itemsToMap.length"
        :markers="itemsToMap"
        :path="overviewPath"
        :pre="true"
        @selectedByMarker="selectedMarker"
      />
    </b-col>
    <b-col cols="12">
      <div class="mb-1">
        <!-- Pendientes -->
        <b-card
          v-if="!!getRiderRutaOpen"
        >
          <b-card-title>
            <span>
              ⬜ <small>(Pendientes)</small> {{ getRiderRutaOpen.nombre.toUpperCase() }}
              <vehiculo-icon
                :color="getRiderRutaOpen.color"
                size="36"
                class="ml-1"
              />
            </span>
          </b-card-title>
          <b-card-text>
            <!-- <span><code>{{ Math.round(getRiderRutaOpen.calculated.distanciaTotal / 1000) }} Km</code> | </span>
            <code>{{ Math.round(getRiderRutaOpen.calculated.duracionTotal / 60) }} min</code> -->
            <span><code>{{ recorridoRestantePendientes.distancia }}</code> | </span>
            <code>{{ recorridoRestantePendientes.duracion }}</code>
          </b-card-text>

          <app-collapse accordion>
            <app-collapse-item
              v-for="(item, n) in getRiderItemsOrdenPedidosPendientes"
              :key="n"
              :title="item.title.toUpperCase()"
            >
              <template v-if="!item.isEnd">
                <p>{{ item.cliente_dir_calle }}, {{ item.cliente_dir_numero }}, {{ item.cliente_dir_cp }}</p>
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  class="text-uppercase"
                  :disabled="isEntregado(item.id)"
                  @click="$router.push({ name: 'bimbo-delivering-item', params: { id: item.id }})"
                >
                  ir a {{ item.cliente_nombre }}
                </b-button>
              </template>
              <b-button
                v-else
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                class="text-uppercase"
                :href="trayectoBase"
                target="_blank"
                @click="$router.go(-1)"
              >
                regresar a base
              </b-button>
            </app-collapse-item>
          </app-collapse>
        </b-card>

        <!-- Terminados -->
        <b-card
          v-if="!!getRiderRutaOpen"
        >
          <b-card-title>
            <span class="text-success">
              ✅ <small>(Terminados)</small> {{ getRiderRutaOpen.nombre.toUpperCase() }}
              <vehiculo-icon
                :color="getRiderRutaOpen.color"
                size="36"
                class="ml-1"
              />
            </span>
          </b-card-title>
          <b-card-text>
            <!-- <span><code>{{ Math.round(getRiderRutaOpen.calculated.distanciaTotal / 1000) }} Km</code> | </span>
            <code>{{ Math.round(getRiderRutaOpen.calculated.duracionTotal / 60) }} min</code> -->
            <span><code>{{ recorridoRestanteTerminados.distancia }}</code> | </span>
            <code>{{ recorridoRestanteTerminados.duracion }}</code>
          </b-card-text>

          <app-collapse accordion>
            <app-collapse-item
              v-for="(item, n) in getRiderItemsOrdenPedidosTerminados"
              :key="n"
              :title="item.title.toUpperCase()"
            >
              <template v-if="!item.isEnd">
                <p>{{ item.cliente_dir_calle }}, {{ item.cliente_dir_numero }}, {{ item.cliente_dir_cp }}</p>
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  class="text-uppercase"
                  @click="$router.push({ name: 'bimbo-delivering-item', params: { id: item.id }})"
                >
                  ir a {{ item.cliente_nombre }}
                </b-button>
              </template>
              <b-button
                v-else
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                class="text-uppercase"
                :href="trayectoBase"
                target="_blank"
                @click="$router.go(-1)"
              >
                regresar a base
              </b-button>
            </app-collapse-item>
          </app-collapse>
        </b-card>

        <b-card v-else>
          Error
        </b-card>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCardText, BRow, BCol, BCard, BButton, BCardTitle,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import VehiculoIcon from '@/assets/images/icons/maps/VehiculoIcon.vue'
import MapaPicking from '@/views/bimbo/components/MapaPickingRider.vue'
// import { onMounted } from '@vue/composition-api'
import useRiders from './useRiders'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    BCardTitle,
    BButton,

    VehiculoIcon,
    AppCollapse,
    AppCollapseItem,
    MapaPicking,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      trayectoBase: 'https://www.google.com/maps/dir//Carrer+de+Pere+IV,+520,+Barcelona',
    }
  },
  setup() {
    const {
      getRiderRutaOpen,
      getRiderItemsOrdenPedidos,
      getRiderItemsOrdenPedidosPendientes,
      getRiderItemsOrdenPedidosTerminados,
      isEntregado,
      recorridoRestante,
      recorridoRestantePendientes,
      recorridoRestanteTerminados,
      itemsToMap,
      overviewPath,
      selectedMarker,
    } = useRiders()

    // onMounted(() => {
    //   console.log('mounted RutaDelivering')
    // })

    return {
      getRiderRutaOpen,
      getRiderItemsOrdenPedidos,
      getRiderItemsOrdenPedidosPendientes,
      getRiderItemsOrdenPedidosTerminados,
      isEntregado,
      recorridoRestante,
      recorridoRestantePendientes,
      recorridoRestanteTerminados,
      itemsToMap,
      overviewPath,
      selectedMarker,
    }
  },
}
</script>
